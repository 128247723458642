<template>
  <div>
    <h2>Seznam malic</h2>
    <v-data-table
      :headers="headers"
      :items="lunches"
      class="elevation-1"
      disable-sort
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-btn color="secondary" dark class="mb-2" @click="fetchData">
            Osveži seznam
          </v-btn>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="900px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                Dodaj malico
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-menu
                    v-model="datePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        label="Teden"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      @input="datePicker = false"
                      first-day-of-week="1"
                    ></v-date-picker>
                  </v-menu>
                  <p>{{ formatedDate }}</p>
                  <v-row>
                    <v-col sm="6">
                      <v-textarea
                        label="Ponedeljek"
                        no-resize
                        outlined
                        rows="2"
                        row-height="30"
                        v-model="editedItem.metadata.monday"
                      />
                    </v-col>
                    <v-col>
                      <v-textarea
                        no-resize
                        outlined
                        rows="2"
                        row-height="30"
                        label="Torek"
                        v-model="editedItem.metadata.tuesday"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-textarea
                        no-resize
                        outlined
                        rows="2"
                        row-height="30"
                        label="Sreda"
                        v-model="editedItem.metadata.wednesday"
                      />
                    </v-col>
                    <v-col>
                      <v-textarea
                        no-resize
                        outlined
                        rows="2"
                        row-height="30"
                        label="Četrtek"
                        v-model="editedItem.metadata.thursday"
                      /> </v-col
                  ></v-row>
                  <v-row>
                    <v-col>
                      <v-textarea
                        no-resize
                        outlined
                        rows="2"
                        row-height="30"
                        label="Petek"
                        v-model="editedItem.metadata.friday"
                      />
                    </v-col>
                    <v-col>
                      <v-textarea
                        no-resize
                        outlined
                        rows="2"
                        row-height="30"
                        label="Posebna ponudba"
                        v-model="editedItem.metadata.special_offer"
                      /> </v-col
                  ></v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="editedItem.metadata.price"
                        outlined
                        label="Cena"
                        :rules="rules"
                        suffix="€"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        outlined
                        v-model="editedItem.metadata.price_2"
                        label="Cena z juho"
                        :rules="rules"
                        suffix="€"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-alert type="error" v-if="error">{{ error }}</v-alert>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="close"> Prekliči </v-btn>
                <v-btn color="primary" dark @click="save" :loading="saving">
                  {{ formTitle }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Izbrišem malico za {{ editedItem.title }}?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Ne</v-btn
                >
                <v-btn color="red" dark @click="deleteItemConfirm">Da</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.title`]="{ item }">
        <span :class="currentWeek(item)"
          >{{ item.title }} (Teden {{ getWeekFromDate(item) }})</span
        >
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small large block class="mr-2" @click="editItem(item)">
          fas fa-edit
        </v-icon>
        <v-icon small @click="deleteItem(item)"> fas fa-minus-circle </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="fetchData"> Reset </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment";
import { getAuth } from "firebase/auth";
import {createBucketClient} from "@cosmicjs/sdk";
import axios from "axios";
import { authAdmin } from "./helper";

const cosmic = createBucketClient({
  bucketSlug: "menciger-production",
  readKey: "3320E6EqWD3o37P4Wy4VC6W2viTpCJCVYM8xNJ3HRxk592sGrZ",
});
const initDate =
  moment().weekday() === 6
    ? moment().add(1, "days")
    : moment().startOf("isoweek");

const backendUrl = "https://diti.si";
// const backendUrl = "http://localhost:8081";
export default {
  data() {
    return {
      loading: false,
      saving: false,
      dialog: false,
      error: "",
      dialogDelete: false,
      lunches: [],
      headers: [
        {
          text: "Teden",
          align: "start",
          value: "title",
        },
        { text: "Actions", value: "actions", sortable: false, align: "end" },
      ],
      editedIndex: -1,
      editedItem: {
        id: "",
        title: "",
        slug: "",
        content: "",
        metadata: {
          monday: "",
          tuesday: "",
          wednesday: "",
          thursday: "",
          friday: "",
          special_offer: "",
          price: 8,
          price_2: 9,
        },
      },
      defaultItem: {
        id: "",
        title: "",
        slug: "",
        content: "",
        metadata: {
          monday: "",
          tuesday: "",
          wednesday: "",
          thursday: "",
          friday: "",
          special_offer: "",
          price: 6.5,
          price_2: 8,
        },
      },
      rules: [(value) => !!value || "Required."],
      date: initDate.add(1, "week").format("YYYY-MM-DD"),
      datePicker: false,
      extensions: [],
    };
  },
  mounted() {
    if (getAuth().currentUser === null) {
      this.$actions.logout();
      return;
    }
    this.fetchData();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Dodaj malico" : "Uredi malico";
    },

    formatedDate() {
      return `Od ${moment(this.date, "YYYY-MM-DD").format(
        "DD.MM.YYYY"
      )} do ${moment(this.date, "YYYY-MM-DD")
        .add(4, "day")
        .format("DD.MM.YYYY")}`;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    close(val) {
      if (!val) this.error = "";
    },
    date(date) {
      const formated = moment(date, "YYYY-MM-DD");
      const monday =
        formated.weekday() === 6
          ? formated.add(1, "days")
          : formated.startOf("isoweek");

      if (!monday.isSame(date)) this.date = monday.format("YYYY-MM-DD");
    },
  },

  methods: {
    logout() {
      this.$actions.logout();
    },
    async fetchData() {
      this.loading = true;
      const data = await cosmic.objects
      .find({ type: "malice"})
      .sort('title');

      this.lunches = data.objects;
      this.loading = false;
    },
    editItem(item) {
      this.editedIndex = this.lunches.indexOf(item);
      this.editedItem = item;
      this.dialog = true;
    },
    getWeekFromDate(item) {
      return moment(item.title, "YYYY-MM-DD").format("w");
    },
    currentWeek(item) {
      return moment(item.title, "YYYY-MM-DD").format("w") ===
        moment().format("w")
        ? "active-week"
        : "";
    },
    deleteItem(item) {
      this.editedIndex = this.lunches.indexOf(item);
      this.editedItem = item;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      var me = this;
      authAdmin().then((token) => {
        axios({
          method: "delete",
          url: backendUrl + "/api/mencinger/lunches/delete",
          data: {
            id: me.editedItem.id,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(function () {
            me.lunches.splice(me.editedIndex, 1);
            me.closeDelete();
          })
          .catch(function (error) {
            me.error = error;
          });
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      var me = this;
      me.saving = true;
      if (getAuth().currentUser === null) {
        this.$actions.logout();
      }
      if (this.editedIndex > -1) {
        authAdmin().then((token) => {
          axios({
            method: "put",
            url: backendUrl + "/api/mencinger/lunches/update",
            data: {
              ...me.editedItem,
              title: moment(me.date, "YYYY-MM-DD").format("YYYY-MM-DD"),
              slug: moment(me.date, "YYYY-MM-DD").format("YYYY-MM-DD"),
            },
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          })
            .then(function (response) {
              const item = response.data.object;
              Object.assign(me.lunches[me.editedIndex], item);
              me.saving = false;
              me.close();
            })
            .catch(function (error) {
              me.error = error;
              me.saving = false;
            });
        });
      } else {
        authAdmin()
          .then((token) => {
            axios({
              method: "post",
              url: backendUrl + "/api/mencinger/lunches/create",
              data: {
                ...me.editedItem,
                title: moment(me.date, "YYYY-MM-DD").format("YYYY-MM-DD"),
              },
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            })
              .then(function (item) {
                me.lunches.push(item);
                me.saving = false;
                me.close();
              })
              .catch(function (error) {
                me.error = error;
                me.saving = false;
              });
          })
          .catch(function (error) {
            me.error = error;
          });
      }
    },
  },
};
</script>
<style scoped>
.active-week {
  font-weight: bold;
}
</style>
